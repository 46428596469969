import React from "react";
import { Navigate } from "react-router-dom";
import { adminRoutePaths } from "@specsheet-common/shared-types";
import { useAuth } from "../../../hooks/contexts/useAuth";

export const GuestGuard: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={adminRoutePaths.admin} />;
  }

  return <>{children}</>;
};
