import React from "react";
import { Dialog, Divider } from "@mui/material";
import { useDialogContext } from "src/hooks/contexts/useDialogContext";
import { DialogTitleStyled } from "./Dialog";

export const DialogWithContext: React.FC = () => {
  const { dialogProps, onClose } = useDialogContext();
  return (
    <Dialog
      fullWidth
      open={Boolean(dialogProps)}
      onClose={onClose}
      scroll="paper"
      maxWidth={dialogProps?.maxWidth}
    >
      {dialogProps?.title && (
        <>
          <DialogTitleStyled
            onClose={onClose}
            title={dialogProps.title}
            subTitle={dialogProps.subTitle}
          />
          <Divider />
        </>
      )}
      {dialogProps && dialogProps.body}
    </Dialog>
  );
};
