import React, { createContext, useRef, useState } from "react";
import { DialogProps as MuiDialogProps } from "@mui/material";

export interface DialogProps {
  body: JSX.Element;
  title?: string;
  subTitle?: string;
  onClose?(): void;
  maxWidth?: MuiDialogProps["maxWidth"];
}

interface DialogContextProps {
  onOpen(dialogProps: DialogProps): void;
  onClose(): void;
  dialogProps?: DialogProps;
}

export const DialogContext = createContext<DialogContextProps | null>(null);

export const DialogProvider: React.FC = ({ children }) => {
  const [dialogProps, setDialogProps] = useState<DialogProps | undefined>();

  const drawerPropsRef = useRef<DialogProps>();

  drawerPropsRef.current = dialogProps;

  const onOpen = ({
    body,
    title,
    subTitle,
    onClose,
    maxWidth,
  }: {
    body: JSX.Element;
    title: string;
    subTitle?: string;
    onClose?(): void;
    maxWidth: MuiDialogProps["maxWidth"];
  }) => {
    setDialogProps({
      body,
      title,
      subTitle,
      onClose,
      maxWidth,
    });
  };

  const onClose = () => {
    const currentDialogProps = drawerPropsRef.current;
    if (currentDialogProps && currentDialogProps.onClose) {
      currentDialogProps.onClose();
    }

    setDialogProps(undefined);
  };

  return (
    <DialogContext.Provider
      value={{
        onOpen,
        onClose,
        dialogProps,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
