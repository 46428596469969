import { Components, Theme } from "@mui/material";

export const MuiDialogTitle: Components<Theme>["MuiDialogTitle"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: 20,
      color: theme.newColors.theme.primary,
    }),
  },
};
