import { Components, Theme } from "@mui/material";
import { MuiAvatar } from "./MuiAvatar";
import { MuiAvatarGroup } from "./MuiAvatarGroup";
import { MuiButton } from "./MuiButton";
import { MuiChip } from "./MuiChip";
import { MuiCircularProgress } from "./MuiCircularProgress";
import { MuiContainer } from "./MuiContainer";
import { MuiDialogActions } from "./MuiDialogActions";
import { MuiDialogTitle } from "./MuiDialogTitle";
import { MuiFormControl } from "./MuiFormControl";
import { MuiIconButton } from "./MuiIconButton";
import { MuiList } from "./MuiList";
import { MuiListItemIcon } from "./MuiListItemIcon";
import { MuiListItemText } from "./MuiListItemText";
import { MuiMenuItem } from "./MuiMenuItem";
import { MuiOutlinedInput } from "./MuiOutlinedInput";
import { MuiTab } from "./MuiTab";
import { MuiTabs } from "./MuiTabs";
import { MuiTextField } from "./MuiTextField";
import { MuiToggleButton } from "./MuiToggleButton";
import { MuiToggleButtonGroup } from "./MuiToggleButtonGroup";
import { MuiTooltip } from "./MuiTooltip";
import { MuiLink } from "./MuiLink";
import { MuiDivider } from "./MuiDivider";
import { MuiSvgIcon } from "./MuiSvgIcon";
import { MuiFormControlLabel } from "./MuiFormControlLabel";
import { MuiAlert } from "./MuiAlert";
import { MuiSwitch } from "./MuiSwitch";

export const components: Components<Theme> = {
  MuiAvatar,
  MuiAvatarGroup,
  MuiButton,
  MuiChip,
  MuiCircularProgress,
  MuiContainer,
  MuiFormControlLabel,
  MuiDialogActions,
  MuiDialogTitle,
  MuiFormControl,
  MuiIconButton,
  MuiList,
  MuiListItemIcon,
  MuiListItemText,
  MuiMenuItem,
  MuiOutlinedInput,
  MuiTab,
  MuiTabs,
  MuiTextField,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTooltip,
  MuiLink,
  MuiDivider,
  MuiSvgIcon,
  MuiAlert,
  MuiSwitch,
};
