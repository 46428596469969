import { ErrorFallback } from "./ErrorFallback";
import React from "react";

interface State {
  hasError: boolean;
  error?: Error;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export class ErrorBoundary extends React.Component<{}, State> {
  constructor(props: never) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error): void {
    // eslint-disable-next-line no-console
    console.log("error: " + String(error));
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback error={this.state.error} />;
    }

    return this.props.children;
  }
}
