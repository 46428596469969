import React, { Fragment, lazy, LazyExoticComponent } from "react";
import { Route, RouteObject, Routes } from "react-router-dom";
import { adminRoutePaths } from "@specsheet-common/shared-types";
import { DashboardLayout } from "./ui/layouts/DashboardLayout";
import { AuthGuard } from "./ui/components/system/AuthGuard";
import { GuestGuard } from "./ui/components/system/GuestGuard";
import { PublicLayout } from "./ui/layouts/PublicLayout";

interface RouteProps extends RouteObject {
  guard?: React.FC;
  layout?: React.FC;
  component: LazyExoticComponent<React.FC>;
  path: string;
  routes?: RouteProps[];
}

export const renderRoutes = (routes: RouteProps[] = []) =>
  routes.map((route: RouteProps, i: number) => {
    const Guard = route.guard || Fragment;
    const Layout = route.layout || Fragment;
    const Component = route.component;

    return (
      <Route
        key={i}
        path={route.path}
        element={
          <Guard>
            <Layout>
              <Component />
            </Layout>
          </Guard>
        }
        children={route.routes ? renderRoutes(route.routes) : undefined}
      />
    );
  });

export const routes: RouteProps[] = [
  {
    path: adminRoutePaths.admin,
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/LegacyAdmin")),
  },
  {
    path: `${adminRoutePaths.adminTable}/:table`,
    layout: DashboardLayout,
    guard: AuthGuard,
    component: lazy(() => import("src/ui/pages/LegacyAdmin/AdminTablePage")),
  },
  {
    path: adminRoutePaths.emails,
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import("src/ui/pages/EmailsPage")),
  },
  {
    path: adminRoutePaths.login,
    guard: GuestGuard,
    layout: PublicLayout,
    component: lazy(() => import("src/ui/pages/LoginPage")),
  },
  { path: "*", component: lazy(() => import("src/ui/pages/NotFoundPage")) },
];

export const TssRoutes = () => {
  return <Routes>{renderRoutes(routes)}</Routes>;
};
