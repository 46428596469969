import classnames from "classnames";
import * as React from "react";
import styles from "./icons.css";
import * as SVG from "./icons-svg";

export type IconSizes = "xs" | "1x" | "sm" | "2x" | "3x" | "4x" | "5x" | "6x";

export type IconColors =
  | "black"
  | "red"
  | "green"
  | "blue"
  | "orange"
  | "white"
  | "gray"
  | "darkGray"
  | "yellow"
  | "navy";

export interface IconProps {
  /** Additional classes */
  className?: string;
  /** Color (Default: black) */
  color?: IconColors;
  /** Size otherwise medium */
  size?: IconSizes;
  /** Callback on click */
  // tslint:disable-next-line: invalid-void
  onClick?(): void;
  /** Title displayed on hovering */
  title?: string;
}

/** All icons for easy iterating */
export const ALL_ICONS: {
  name: string;
  Icon(props: IconProps): JSX.Element;
}[] = [];

export function makeIconComponent(
  name: string,
  svg: JSX.Element
): (props: IconProps) => JSX.Element {
  const Icon = (props: IconProps) => {
    const size = props.size || "1x";
    return (
      <div
        title={props.title}
        className={classnames(
          styles.icon,
          styles[`icon-${size}`],
          props.color && styles[props.color],
          props.onClick && styles.clickable,
          props.className
        )}
        onClick={props.onClick}
      >
        {svg}
      </div>
    );
  };
  ALL_ICONS.push({
    name,
    Icon,
  });
  return Icon;
}

export const CloseIcon = makeIconComponent("Close", SVG.CloseSvg);
export const NotificationIcon = makeIconComponent(
  "Notification",
  SVG.NotificationSvg
);
export const QuestionIcon = makeIconComponent("Question", SVG.QuestionSvg);

export const ToggleIcon = makeIconComponent("Toggle", SVG.ToggleSVG);
