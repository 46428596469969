import { Paginated, Unit } from "../adl-gen/common";
import * as AR from "../adl-gen/common/adminui/api";
import { MetaAdlDecl } from "../adl-gen/common/adminui/db";
import { DbKey, WithDbId } from "../adl-gen/common/db";
import {
  HttpGet,
  HttpPost,
  snHttpGet,
  snHttpPost,
} from "../adl-gen/common/http";
import { TableQuery } from "../adl-gen/common/tabular";
import { ATypeExpr, DeclResolver } from "../adl-gen/runtime/adl";
import {
  createJsonBinding,
  getAnnotation,
  JsonBinding,
} from "../adl-gen/runtime/json";
import { scopedNamesEqual } from "../adl-gen/runtime/utils";
import * as adlast from "../adl-gen/sys/adlast";

import { HttpFetch, HttpRequest } from "./http";
import { HttpServiceError } from "./http-service-error";
import { AdminService } from "./adminService";
import {
  AppRequests,
  makeAppRequests,
  snAppRequests,
} from "../adl-gen/kachemedia/specsheet/requests";
import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";

/**
 * Combines a async function to make a get request
 * along with the metadata required to plug that request
 * into an admin ui
 */
export interface GetFn<O> {
  description(): string;
  rtype: HttpGet<O>;
  call(): Promise<O>;
}

/**
 * Combines a async function to make a post request
 * along with the metadata required to plug that request
 * into an admin ui
 */
export interface PostFn<I, O> {
  description(): string;
  rtype: HttpPost<I, O>;
  call(req: I): Promise<O>;
}

/**
 * The propte backend service.
 */
export abstract class HttpServiceBase implements AdminService {
  requests: AppRequests;
  requestsDecl: adlast.ScopedDecl;

  postAdminQueryTables: PostFn<TableQuery, Paginated<AR.Table>>;
  postAdminQueryDecls: PostFn<TableQuery, Paginated<MetaAdlDecl>>;
  postAdminCreate: PostFn<AR.CreateReq, AR.DbResult<DbKey<AR.DbRow>>>;
  postAdminQuery: PostFn<AR.QueryReq, Paginated<WithDbId<AR.DbRow>>>;
  postAdminUpdate: PostFn<AR.UpdateReq, AR.DbResult<Unit>>;
  postAdminDelete: PostFn<AR.DeleteReq, AR.DbResult<Unit>>;

  constructor(
    /** Fetcher over HTTP */
    private readonly http: HttpFetch,
    /** Base URL of the API endpoints */
    private readonly baseUrl: string,
    /** Resolver for ADL types */
    private readonly resolver: DeclResolver,
    /** Token manager storing the authentication tokens */
    private readonly getAccessTokenSilently: Auth0ContextInterface["getAccessTokenSilently"],
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    private readonly handleError: (error: HttpServiceError) => void
  ) {
    this.requests = makeAppRequests({});
    this.requestsDecl = resolver(snAppRequests);

    this.postAdminQueryTables = this.mkPostFn(this.requests.admin.queryTables);
    this.postAdminQueryDecls = this.mkPostFn(this.requests.admin.queryDecls);
    this.postAdminCreate = this.mkPostFn(this.requests.admin.create);
    this.postAdminQuery = this.mkPostFn(this.requests.admin.query);
    this.postAdminUpdate = this.mkPostFn(this.requests.admin.update);
    this.postAdminDelete = this.mkPostFn(this.requests.admin.delete);
  }

  async adminQueryTables(req: TableQuery): Promise<Paginated<AR.Table>> {
    return this.postAdminQueryTables.call(req);
  }

  async adminQueryDecls(req: TableQuery): Promise<Paginated<MetaAdlDecl>> {
    return this.postAdminQueryDecls.call(req);
  }

  async adminCreate(req: AR.CreateReq): Promise<AR.DbResult<DbKey<AR.DbRow>>> {
    return this.postAdminCreate.call(req);
  }

  async adminQuery(req: AR.QueryReq): Promise<Paginated<WithDbId<AR.DbRow>>> {
    return this.postAdminQuery.call(req);
  }

  async adminUpdate(req: AR.UpdateReq): Promise<AR.DbResult<Unit>> {
    return this.postAdminUpdate.call(req);
  }

  async adminDelete(req: AR.DeleteReq): Promise<AR.DbResult<Unit>> {
    return this.postAdminDelete.call(req);
  }

  protected mkGetFn<O>(rtype: HttpGet<O>): GetFn<O> {
    const jb = createJsonBinding(this.resolver, rtype.respType);
    const { actionName, description } = this.getRequestAttributes(
      snHttpGet,
      rtype.path
    );
    return {
      description: () => description,
      rtype,
      call: () => {
        return this.getAdl(rtype.path, jb, actionName);
      },
    };
  }

  protected mkPostFn<I, O>(rtype: HttpPost<I, O>): PostFn<I, O> {
    const bb = createBiBinding<I, O>(this.resolver, rtype);
    const { actionName, description } = this.getRequestAttributes(
      snHttpPost,
      rtype.path
    );
    return {
      description: () => description,
      rtype,
      call: (req: I) => {
        return this.postAdl(rtype.path, bb, req, actionName);
      },
    };
  }

  protected getRequestAttributes(
    method: adlast.ScopedName,
    path: string
  ): { actionName: string; description: string } {
    if (this.requestsDecl.decl.type_.kind !== "struct_") {
      throw new Error("BUG: requestDecl is not a struct");
    }
    const struct = this.requestsDecl.decl.type_.value;
    for (const field of struct.fields) {
      if (
        field.typeExpr.typeRef.kind === "reference" &&
        scopedNamesEqual(field.typeExpr.typeRef.value, method)
      ) {
        const req = this.requests[field.name];
        if (req.path === path) {
          return {
            actionName: field.name,
            description:
              getAnnotation(
                // eslint-disable-next-line no-use-before-define,@typescript-eslint/no-use-before-define
                createJsonBinding(this.resolver, texprDocString),
                field.annotations
              ) || "",
          };
        }
      }
    }
    //tslint:disable:no-console
    //console.log("WARNING: field not found for path ", method, path);
    return { actionName: "??", description: "??" };
  }

  protected async getAdl<O>(
    path: string,
    respJB: JsonBinding<O>,
    actionName: string
  ): Promise<O> {
    return this.requestAdl("get", path, null, respJB, actionName);
  }

  protected async postAdl<I, O>(
    path: string,
    // eslint-disable-next-line no-use-before-define
    post: BiBinding<I, O>,
    req: I,
    actionName: string
  ): Promise<O> {
    const jsonArgs = post.reqJB.toJson(req);
    return this.requestAdl("post", path, jsonArgs, post.respJB, actionName);
  }

  protected async requestAdl<O>(
    method: "get" | "post",
    path: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    jsonArgs: {} | null,
    respJB: JsonBinding<O>,
    /** Publicly consumable action of the request for error alerting purposes */
    actionName: string
  ): Promise<O> {
    //console.log('requestAdl this.baseUrl: ', this.baseUrl);

    // Construct request
    const authToken = await this.getAccessTokenSilently();
    const headers: { [key: string]: string } = {};
    if (authToken) {
      headers["X-Auth-Token"] = authToken;
    }
    const httpReq: HttpRequest = {
      url: this.baseUrl + path,
      headers,
      method,
      body: jsonArgs ? JSON.stringify(jsonArgs) : undefined,
    };

    //console.log('httpReq: ', httpReq);

    // Make request
    const resp = await this.http.fetch(httpReq);

    //console.log('resp:', resp);

    // Check for errors
    if (!resp.ok) {
      const bodyText = await resp.text();
      let publicMessageFragment = "";
      try {
        const bodyJson = JSON.parse(bodyText);
        if (bodyJson.publicMessage) {
          publicMessageFragment = `: ${bodyJson.publicMessage}`;
        }
      } catch {
        // Not JSON
      }

      const error = new HttpServiceError(
        `Encountered server error attempting to call ${actionName} ${publicMessageFragment}`,
        `${httpReq.method} request to ${httpReq.url} failed: ${resp.statusText} (${resp.status}): ${bodyText}`,
        resp.status
      );
      this.handleError(error);
      throw error;
    }

    // Parse response
    try {
      //console.log('parse response...');
      const respJson = await resp.json();
      //console.log('respJson:', respJson);
      // @ts-expect-error skip
      return respJB.fromJson(respJson);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      const error = new HttpServiceError(
        "Encountered parse error attempting to call " + actionName,
        // @ts-expect-error strict mode
        e.getMessage(),
        resp.status
      );
      this.handleError(error);
      throw error;
    }
  }
}

interface BiTypeExpr<I, O> {
  reqType: ATypeExpr<I>;
  respType: ATypeExpr<O>;
}

interface BiBinding<I, O> {
  reqJB: JsonBinding<I>;
  respJB: JsonBinding<O>;
}

function createBiBinding<I, O>(
  resolver: DeclResolver,
  rtype: BiTypeExpr<I, O>
): BiBinding<I, O> {
  return {
    reqJB: createJsonBinding(resolver, rtype.reqType),
    respJB: createJsonBinding(resolver, rtype.respType),
  };
}

const texprDocString: ATypeExpr<string> = {
  value: {
    typeRef: {
      kind: "reference",
      value: { moduleName: "sys.annotations", name: "Doc" },
    },
    parameters: [],
  },
};
