import React from "react";

/** Collection of all the svg icons used in the app */

/** Close Icon SVG */
export const CloseSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M7,5l5-5,2,2L9,7l5,5-2,2L7,9,2,14,0,12,5,7,0,2,2,0Z" />
  </svg>
);

/** Notification Icon SVG */
export const NotificationSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M13.25,11,12.3,9.83V6.34A5.17,5.17,0,0,0,8.82,1.46a1.87,1.87,0,0,0-3.65,0A5.18,5.18,0,0,0,1.69,6.34V9.83L.74,11a.68.68,0,0,0-.09.76.72.72,0,0,0,.63.38H4.54a2.55,2.55,0,0,0,4.92,0h3.26a.71.71,0,0,0,.63-.38A.68.68,0,0,0,13.25,11ZM2.78,10.71l.16-.19a.72.72,0,0,0,.16-.44V6.34A3.83,3.83,0,0,1,6,2.65a.73.73,0,0,0,.51-.82.46.46,0,1,1,.91,0v.06A.7.7,0,0,0,8,2.65,3.82,3.82,0,0,1,10.9,6.33v3.75a.7.7,0,0,0,.16.44l.16.19ZM7,12.62a1.17,1.17,0,0,1-1-.53H8a1.13,1.13,0,0,1-1,.55Z" />
  </svg>
);

/** Question Icon SVG */
export const QuestionSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M7,14a7,7,0,1,1,7-7A7,7,0,0,1,7,14ZM7,1.58A5.42,5.42,0,1,0,12.42,7h0A5.42,5.42,0,0,0,7,1.58Z" />
    <path d="M6.2,8.23a2.64,2.64,0,0,1,.16-1,2.09,2.09,0,0,1,.58-.77,3,3,0,0,0,.56-.61,1,1,0,0,0,.14-.5c0-.52-.24-.79-.73-.79a.76.76,0,0,0-.55.21.87.87,0,0,0-.22.59H4.79a1.8,1.8,0,0,1,.58-1.39,2.25,2.25,0,0,1,1.54-.5,2.2,2.2,0,0,1,1.54.48A1.64,1.64,0,0,1,9,5.26,1.75,1.75,0,0,1,8.82,6a3.44,3.44,0,0,1-.62.79l-.38.34a1.29,1.29,0,0,0-.41.8l0,.29ZM6.06,9.66a.7.7,0,0,1,.22-.51A.71.71,0,0,1,6.82,9a.71.71,0,0,1,.54.2.7.7,0,0,1,.22.51.69.69,0,0,1-.21.51.85.85,0,0,1-1.1,0A.69.69,0,0,1,6.06,9.66Z" />
  </svg>
);

/** Toggle icon */
export const ToggleSVG = (
  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8898 4.48894L13.667 4.26613C13.5195 4.11865 13.281 4.11865 13.1335 4.26613L7.00176 10.401L0.866885 4.26613C0.719397 4.11865 0.480905 4.11865 0.333417 4.26613L0.110616 4.48894C-0.036872 4.63642 -0.036872 4.87492 0.110616 5.0224L6.73189 11.6468C6.87938 11.7943 7.11787 11.7943 7.26536 11.6468L13.8866 5.0224C14.0373 4.87492 14.0373 4.63642 13.8898 4.48894Z" />
  </svg>
);
