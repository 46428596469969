import { Components, Theme } from "@mui/material";

export const MuiDialogActions: Components<Theme>["MuiDialogActions"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.metrics.unit * 6,
      justifyContent: "space-between",
    }),
  },
};
