import { UpdateFn, VEditor } from "@adltools/adl-veditor";
import { FieldFns, regexStringFieldFns } from "@adltools/fields";
import * as React from "react";
import { ExternalIdSearchField } from "./ExternalIdSearchField";

interface DbKeyVEditorProps {
  tableName: string;
  tableHref?: string;
}

export function dbKeyFieldFns(tableName: string): FieldFns<string> {
  return regexStringFieldFns("^\\S+$", `an existing ${tableName} id`);
}

export function dbKeyVEditor(props: DbKeyVEditorProps) {
  const fieldfns = dbKeyFieldFns(props.tableName);
  return fieldVEditor(props, fieldfns);
}

type FieldState = string;
type FieldEvent = string;

export function fieldVEditor<T>(
  props: DbKeyVEditorProps,
  fieldfns: FieldFns<T>
): VEditor<T, FieldState, FieldEvent> {
  return {
    initialState: "",
    stateFromValue: (v) => fieldfns.toText(v),
    validate: (s) => listFromNull(fieldfns.validate(s)),
    valueFromState: (s) => fieldfns.fromText(s),
    update: (_state: FieldState, event: FieldEvent) => event,
    render: (
      state: FieldEvent,
      canEdit: boolean,
      onUpdate: UpdateFn<FieldEvent>
    ) => {
      if (canEdit) {
        return (
          <ExternalIdSearchField
            fieldfns={fieldfns}
            validate={fieldfns.validate}
            onUpdate={onUpdate}
            value={state}
            tableHref={props.tableHref}
            tableName={props.tableName}
          />
        );
      } else {
        return <div>{state}</div>;
      }
    },
  };
}

function listFromNull<T>(v: T | null): T[] {
  if (v === null) {
    return [];
  } else {
    return [v];
  }
}
