import * as React from "react";

import {
  CellContent,
  CellLoc,
  CellPopup,
  Column,
  SEMANTIC_THEME,
  TableFactory,
} from "../adl-table";

// This file contains the `AdlTable<T>` react component that displays
// a table of values of type T. T can be any type and all, rendering
// is done in terms of column definitions (type AT.Column<T>) provided by
// the user.
//
// There is also an associated helper function `getAdlTableInfo` that
// will create the appropriate column definitions from an ADL
// structure type. Hence, one can compose these to build a table of
// ADL values:
//
//   function demo<T>(declResolver: DeclResolver, typeExpr: ATypeExpr<T>, values: T[]) : JSX.Element {
//     const adlTableInfo = getAdlTableInfo(declResolver, typeExpr);
//     const props : AdlTableProps<T> = {
//       columns: adlTableInfo.columns.map(c => c.column),
//       values
//     }
//     const AdlTableT: new() => AdlTable<T> = AdlTable as any;
//     return React.createElement(AdlTableT, props);
//   }

export interface AdlTableProps<T, CI> {
  /** How to display the columns */
  columns: Column<T, CI>[];

  /** The values to display */
  values: T[];

  /** A callback when a cell is clicked */
  onClick?(loc: CellLoc<CI>): void;

  /**
   * An intercept to override the content at specific cell locations
   */
  customRender?(loc: CellLoc<CI>, content: () => CellContent): CellContent;

  /**
   * A means of overriding the table construction in order to provide custom styling
   */
  theme?: TableFactory;

  /**
   * The popup to show over a cell or header cell, if desired
   */
  cellPopup?(): CellPopup<CI> | null;
}

export class AdlTable<T, CI> extends React.PureComponent<AdlTableProps<T, CI>> {
  constructor(props: AdlTableProps<T, CI>) {
    super(props);
  }

  render() {
    // tslint:disable-next-line: no-this-assignment
    const me = this;
    const theme = this.props.theme || SEMANTIC_THEME;

    const popup = this.props.cellPopup && this.props.cellPopup();

    const headerCells = this.props.columns.map((col, i) =>
      theme.headerCell(
        i,
        col.header,
        this.popupContentIfHeaderMatches(col.id, popup)
      )
    );

    const createContent = (
      c: Column<T, CI>,
      value: T,
      loc: CellLoc<CI>
    ): CellContent => {
      if (me.props.customRender) {
        return me.props.customRender(loc, () => c.content(value, loc.rowi));
      } else {
        return c.content(value, loc.rowi);
      }
    };

    const createRow = (value: T, rowi: number): JSX.Element => {
      const cells = me.props.columns.map((col, coli) => {
        const loc: CellLoc<CI> = { section: "body", rowi, coli: col.id };
        const content = createContent(col, value, loc);
        const onClick = me.props.onClick
          ? me.onClick.bind(me, loc)
          : () => undefined;
        return theme.tableCell(
          coli,
          onClick,
          content,
          this.popupContentIfMatches(loc, popup)
        );
      });
      let className = "";
      if (popup && popup.loc.rowi === rowi) {
        className = "highlighted-row";
      }

      return theme.tableRow(rowi, cells, className);
    };

    const rows = this.props.values.map(createRow);
    return theme.table(headerCells, rows);
  }

  popupContentIfHeaderMatches(
    coli: CI,
    popup?: CellPopup<CI> | null
  ): JSX.Element | null {
    return this.popupContentIfMatches(
      { section: "header", rowi: 0, coli },
      popup
    );
  }

  popupContentIfMatches(
    loc: CellLoc<CI>,
    popup?: CellPopup<CI> | null
  ): JSX.Element | null {
    if (!popup) {
      return null;
    }
    if (
      popup.loc.section === loc.section &&
      // tslint:disable-next-line: strict-comparisons
      popup.loc.coli === loc.coli &&
      popup.loc.rowi === loc.rowi
    ) {
      return popup.render();
    }
    return null;
  }

  onClick(loc: CellLoc<CI>) {
    if (this.props.onClick) {
      this.props.onClick(loc);
    }
  }
}

// Hacks to turn the generic components into universal ones
// (see https://github.com/Microsoft/TypeScript/issues/3960)
export type AdlTableU = new () => AdlTable<unknown, string>;
export const AdlTableU: AdlTableU = AdlTable as AdlTableU;
