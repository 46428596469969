import { Box, Stack } from "@mui/material";
import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import ReactModal from "react-modal";
import { store } from "./";
import styles from "./modal.css";
import {
  CloseIcon,
  IconColors,
} from "../../temporaryComponentsToShare/icons/icons";

/** Props for the component */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ModalCanvasProps {
  // None
}

export interface ModalHeaderProps {
  /** Header component */
  el: JSX.Element;
  /** Close icon color */
  closeIconColor?: IconColors;
}

/** Props to specify the content and behavior of a modal */
export interface ModalInstance {
  /** Header */
  header?: ModalHeaderProps;
  /** Content to show in the modal, excluding buttons */
  content: JSX.Element;
  /** Accesibility label to show to screen readers */
  a11yLabel: string;
  /** Modal should close when the overlay is clicked */
  shouldCloseOnOverlayClick?: boolean;
  /** Buttons of the modal */
  buttons: JSX.Element[];
  /**
   * Callback when the user performs a non-button action to close the modal,
   * e.g. close icon, push escape, click outside the modal if enabled.
   */
  onRequestClose(): void;
  /** Custom className for the modal container */
  className?: string;
  /** Custom className for the modal overlay */
  overlayClassName?: string;
  /** Custom className for the modal container */
  containerClassName?: string;
  /** Custom className for the modal body */
  bodyClassName?: string;
  /** Custom className for the modal buttons area */
  buttonsClassName?: string;
}

/**
 * Modal displayer to be mounted once in the app.
 */
@observer
export class ModalCanvas extends React.Component<ModalCanvasProps> {
  /** Renders the contents of a modal instance */
  renderModal(instance: ModalInstance) {
    const Modal = observer(() => (
      <Stack
        sx={{
          overflowY: "auto",
          maxHeight: "100%",
          position: "relative",
        }}
        className={classnames(instance.containerClassName)}
      >
        <Box
          sx={{ display: "flex", flex: "0 0 auto" }}
          className={classnames(instance.bodyClassName)}
        >
          <CloseIcon
            title="Close"
            color={instance.header?.closeIconColor}
            className={styles.closeIcon}
            onClick={instance.onRequestClose}
          />
          {instance.header ? <>{instance.header.el}</> : <Box height={2} />}
        </Box>

        <Box
          sx={{
            my: 2,
            px: 3,
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            overflow: "auto",
            img: {
              maxWidth: "100%",
            },
          }}
        >
          {instance.content}
        </Box>
        <Box
          sx={{ flex: "0 0 auto" }}
          className={classnames(styles.buttons, instance.buttonsClassName)}
        >
          {instance.buttons.map((b, i) => (
            <React.Fragment key={i}>{b}</React.Fragment>
          ))}
        </Box>
      </Stack>
    ));
    return <Modal />;
  }

  /** Renders the component */
  render() {
    return (
      <ReactModal
        className={classnames(styles.modal, store.instance?.className)}
        overlayClassName={classnames(
          styles.overlay,
          store.instance?.overlayClassName
        )}
        isOpen={!!store.instance}
        contentLabel={store.instance && store.instance.a11yLabel}
        onRequestClose={store.instance && store.instance.onRequestClose}
        shouldCloseOnOverlayClick={
          store.instance && store.instance.shouldCloseOnOverlayClick
        }
        style={{ content: { padding: 0 }, overlay: { zIndex: 1000 } }}
      >
        {store.instance ? this.renderModal(store.instance) : undefined}
      </ReactModal>
    );
  }
}
