import { Components, Theme } from "@mui/material";

export const MuiTextField: Components<Theme>["MuiTextField"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.newColors.neutral.white,
      "& .MuiOutlinedInput-root": {
        padding: theme.spacing(2),
      },
      "& .MuiSvgIcon-root": {
        fontSize: theme.typography.h4.fontSize,
      },
      "& .MuiOutlinedInput-input": {
        margin: theme.spacing(0, 2),
        padding: 0,
      },
      "& .MuiInputAdornment-root": {
        width: theme.spacing(6),
        margin: 0,
      },
    }),
  },
};
