import { Components, Theme } from "@mui/material";

export const MuiIconButton: Components<Theme>["MuiIconButton"] = {
  styleOverrides: {
    sizeMedium: ({ theme }) => ({
      padding: theme.spacing(1),
      ["& .MuiSvgIcon-root"]: {
        padding: 1,
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    }),
  },
};
