import { observer } from "mobx-react";
import React from "react";
import styles from "./question.css";
import {
  IconProps,
  QuestionIcon,
} from "../../../temporaryComponentsToShare/icons/icons";

interface QuestionModalContentProps {
  content: string;
}

/** Question modal content */
@observer
export class QuestionModalContent extends React.Component<QuestionModalContentProps> {
  /** Renders the content of the modal */
  render() {
    return (
      <div className={styles.container}>
        <p className={styles.content}>{this.props.content}</p>
      </div>
    );
  }
}

interface QuestionModalHeaderProps {
  title: string;
  Icon?: React.ComponentType<IconProps>;
}

/** Question modal content */
export class QuestionModalHeader extends React.Component<QuestionModalHeaderProps> {
  /** Renders the modal header */
  render() {
    return (
      <div className={styles.header}>
        {this.props.Icon ? (
          <this.props.Icon className={styles.icon} color="blue" />
        ) : (
          <QuestionIcon className={styles.icon} color="blue" />
        )}
        <h6 className={styles.title}>{this.props.title}</h6>
      </div>
    );
  }
}
