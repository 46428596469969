import { Components, Theme } from "@mui/material";

export const MuiMenuItem: Components<Theme>["MuiMenuItem"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ":hover": {
        backgroundColor: theme.newColors.utility.blueLight,
        color: theme.newColors.theme.primary,
      },
      borderRadius: theme.metrics.borderRadius * theme.metrics.unit,
      color: theme.newColors.neutral.primary,
    }),
  },
};
