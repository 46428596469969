import { PaperProps, PopoverActions, PopoverOrigin } from "@mui/material";
import React, { createContext, useRef, useState } from "react";

interface PopoverProps {
  anchorEl: Element;
  anchorOrigin?: PopoverOrigin;
  body: JSX.Element;
  transformOrigin?: PopoverOrigin;
  PaperProps?: Partial<PaperProps>;
  action?: React.Ref<PopoverActions>;
  onClose?(): void;
}

interface PopoverContextProps {
  onOpen(props: PopoverProps): void;
  onClose(): void;
  popoverProps?: PopoverProps;
}

export const PopoverContext = createContext<PopoverContextProps | null>(null);

export const PopoverProvider: React.FC = ({ children }) => {
  const [popoverProps, setPopoverProps] = useState<PopoverProps | undefined>();

  const popoverPropsRef = useRef<PopoverProps>();

  popoverPropsRef.current = popoverProps;

  const onOpen = (props: PopoverProps) => {
    setPopoverProps(props);
  };

  const onClose = () => {
    const currentPopoverProps = popoverPropsRef.current;
    if (currentPopoverProps && currentPopoverProps.onClose) {
      currentPopoverProps.onClose();
    }

    setPopoverProps(undefined);
  };

  return (
    <PopoverContext.Provider
      value={{
        onOpen,
        onClose,
        popoverProps,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};
