import { ConstantsMap, getEnv } from "./util";
interface Values {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
  namespace: string;
}
const data: ConstantsMap<Values> = {
  prod: {
    domain: "auth0.thespecsheet.com",
    clientId: "ipgfovPIU6TkRkdxyrLP3UbYZ3Kg9XXv",
    redirectUri: "https://ops.thespecsheet.com",
    audience: "https://app.thespecsheet.com",
    namespace: "https://app.thespecsheet.com",
  },
  "app.prod": {
    domain: "auth0.thespecsheet.com",
    clientId: "ipgfovPIU6TkRkdxyrLP3UbYZ3Kg9XXv",
    redirectUri: "https://ops.thespecsheet.com",
    audience: "https://app.thespecsheet.com",
    namespace: "https://app.thespecsheet.com",
  },
  "app.develop": {
    domain: "auth0.thespecsheet.com",
    clientId: "XIyXAaaNKT6DypozNhKczkfmD13vhfll",
    redirectUri: "https://ops.develop.thespecsheet.com/",
    audience: "https://app.develop.thespecsheet.com",
    namespace: "https://app.thespecsheet.com",
  },
  "app.testing": {
    domain: "auth0.thespecsheet.com",
    clientId: "F6K10GcDovT9PlCusBgoV384jj3djrMd",
    redirectUri: "https://ops.testing.thespecsheet.com/",
    audience: "https://app.testing.thespecsheet.com",
    namespace: "https://app.thespecsheet.com",
  },
  "app.staging": {
    domain: "auth0.thespecsheet.com",
    clientId: "xodYGU2BbeOjd90hd1IPhb2mBBwH5KGq",
    redirectUri: "https://ops.staging.thespecsheet.com",
    audience: "https://app.staging.thespecsheet.com",
    namespace: "https://app.thespecsheet.com",
  },
  local: {
    domain: "auth0.thespecsheet.com",
    clientId: "aqu0sQlcf3eKcWIszCQUuET1792oLJh0",
    redirectUri: "http://localhost:3005/",
    audience: "https://app.local.thespecsheet.com",
    namespace: "https://app.thespecsheet.com",
  },
};

const auth0Config = data[getEnv()];

export const domain = auth0Config.domain;
export const clientId = auth0Config.clientId;
export const redirectUri = auth0Config.redirectUri;
export const audience = auth0Config.audience;
export const namespace = auth0Config.namespace;
