import React, { createContext, useRef, useState } from "react";

type DrawerProps = {
  body: JSX.Element;
  title?: string;
  onClose?(): void;
  id?: string;
  expandable?: boolean;
  expanded?: boolean;
};

interface DrawerContextProps {
  open(props: DrawerProps): void;
  close(): void;
  expandToggle(): void;
  drawerProps?: DrawerProps;
}
export const DrawerContext = createContext<DrawerContextProps | null>(null);

export const DrawerProvider: React.FC = ({ children }) => {
  const [drawerProps, setDrawerProps] = useState<DrawerProps | undefined>();
  const [expanded, setExpanded] = useState<Map<string | undefined, boolean>>(
    new Map()
  );
  const drawerPropsRef = useRef<DrawerProps>();

  drawerPropsRef.current = drawerProps;

  const onOpen = (props: DrawerProps) => {
    if (props.id && !expanded.has(props.id)) {
      setExpanded((state) => state.set(props.id, Boolean(props.expanded)));
    }
    setDrawerProps({
      ...props,
      expanded: props.id ? !!expanded.get(props.id) : Boolean(props.expanded),
    });
  };

  const onExpandToggle = () => {
    const currerntDrawerProps = drawerPropsRef.current;
    if (currerntDrawerProps) {
      if (currerntDrawerProps.id) {
        setExpanded((prev) =>
          prev.set(currerntDrawerProps.id, !currerntDrawerProps.expanded)
        );
      }
      setDrawerProps({
        ...currerntDrawerProps,
        expanded: !currerntDrawerProps.expanded,
      });
    }
  };

  const onClose = () => {
    const currerntDrawerProps = drawerPropsRef.current;

    if (currerntDrawerProps && currerntDrawerProps.id) {
      setExpanded((prev) =>
        prev.set(currerntDrawerProps.id, Boolean(currerntDrawerProps.expanded))
      );
    }

    if (currerntDrawerProps && currerntDrawerProps.onClose) {
      currerntDrawerProps.onClose();
    }
    setDrawerProps(undefined);
  };

  return (
    <DrawerContext.Provider
      value={{
        open: onOpen,
        close: onClose,
        expandToggle: onExpandToggle,
        drawerProps,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
