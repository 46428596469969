import { Components, Theme } from "@mui/material";

export const MuiTabs: Components<Theme>["MuiTabs"] = {
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiTabs-indicator": {
        height: theme.metrics.borderWidth,
      },
      "& .MuiBox-root": {
        margin: theme.spacing(0, 1),
      },
      "& .MuiTabScrollButton-root": {
        background: `linear-gradient(to right, #fff 66%, rgba(255,
          255, 255, 0))`,
        opacity: 1,
        position: "absolute",
        height: theme.spacing(13),
        width: theme.spacing(22),
        zIndex: 100,
        color: theme.newColors.theme.primary,
      },
      "& .MuiTabScrollButton-root:last-child": {
        right: 0,
        background: `linear-gradient(to left, #fff 66%, rgba(255,
          255, 255, 0))`,
      },
      "& .MuiTabScrollButton-root > svg": {
        fontSize: theme.typography.h4.fontSize,
      },
    }),
  },
};
