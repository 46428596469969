import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import {
  auth0SubRoute,
  coreRoutes,
  ResendAuth0VerificationEmailReq,
  urlBuilder,
} from "@specsheet-common/shared-types";
import { useApi } from "../../../../configuration/api";

interface MutationProps {
  // who need types anyway
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?(data: any): void;
  onError?(): void;
}

export const useResendVerificationEmail = ({
  onError,
  onSuccess,
}: MutationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();

  const effect = async (
    data: ResendAuth0VerificationEmailReq
    // who need types anyway
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const res = await api.post<never, unknown, ResendAuth0VerificationEmailReq>(
      urlBuilder({
        root: coreRoutes.auth0,
        sub: auth0SubRoute.resendVerification,
      }),
      data
    );
    return res;
  };

  return useMutation(effect, {
    onSuccess: (data) => {
      enqueueSnackbar("Verification email resent", {
        variant: "success",
        autoHideDuration: 5000,
      });

      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: () => {
      if (onError) {
        onError();
      }

      enqueueSnackbar("Sorry, something went wrong.", {
        variant: "error",
      });
    },
  });
};
