import { BrowserHttp } from "./service/browser-http";
import { HttpWithRetries } from "./service/retrying-http";
import { RESOLVER } from "./adl-gen/resolver";
import {
  ActionFactory,
  AdminStore,
  makePostAction,
} from "./ui/pages/LegacyAdmin/store";
import { HttpService } from "./service/http-service";
import { HttpServiceError } from "./service/http-service-error";
import { legacyApiURL } from "./configuration/constants/url";
import { useAuth0 } from "@auth0/auth0-react";

export const useSharedResources = () => {
  const http = new HttpWithRetries(new BrowserHttp(), 8, 100);
  const { getAccessTokenSilently } = useAuth0();

  const service = new HttpService(
    http,
    `${legacyApiURL}/_a`,
    RESOLVER,
    getAccessTokenSilently,
    (error: HttpServiceError) => {
      // Check if it's an authorization error
      if (error.status === 401) {
        const publicMessageFragment = error.publicMessage
          ? `: ${error.publicMessage}`
          : "";
        // eslint-disable-next-line no-console
        console.log(`An authorization error occurred ${publicMessageFragment}`);
        return;
      }
    }
  );

  // Declare the actions we want to show in the adminui
  const adminUiActions: ActionFactory[] = [
    makePostAction(service.postOpsCreateOrganisation),
    makePostAction(service.postOpsCreateUser),
  ];

  let adminStore: AdminStore;
  const getAdminStore = () => {
    if (adminStore) {
      return adminStore;
    }

    return new AdminStore(service, () => "rwar", adminUiActions, RESOLVER);
  };

  return {
    service,
    getAdminStore,
  };
};
