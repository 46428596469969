import React from "react";
import { Button } from "@mui/material";

interface ErrorFallbackProps {
  error?: Error;
}

export const ErrorFallback = ({ error }: ErrorFallbackProps): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ color: "red", fontSize: 20 }}>Something went wrong </div>
      {error && (
        <div style={{ fontSize: 20, marginTop: 20 }}>{error.message}</div>
      )}
      <div style={{ fontSize: 20, marginTop: 20 }}>
        <Button
          variant={"contained"}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Please reload the page and try again
        </Button>
      </div>
    </div>
  );
};
