// Representation of a date format

export interface DateFormat {
  parse(text: string): Date | null;
  format(date: Date): string;
  template: string;
}

// Internal representation for dates
interface Date {
  year: number;
  month: number;
  day: number;
}

const ISO_DATE_REGEXP = new RegExp(
  "^[ \t]*(([0-9][0-9][0-9][0-9])-?([0-9][0-9])-?([0-9][0-9]))[ \t]*$"
);

export const ISO_FORMAT: DateFormat = {
  parse(text: string): Date | null {
    const m = text.match(ISO_DATE_REGEXP);
    if (m) {
      const year = parseInt(m[2], 10);
      const month = parseInt(m[3], 10);
      const day = parseInt(m[4], 10);
      if (
        month >= 1 &&
        month <= 12 &&
        day >= 1 &&
        day <= daysInMonth(year, month)
      ) {
        return { year, month, day };
      }
    }
    return null;
  },
  format(date: Date): string {
    return "" + date.year + "-" + padn(date.month, 2) + "-" + padn(date.day, 2);
  },
  template: "YYYY-MM-DD",
};

const DDSMMSYYYY_DATE_REGEXP = new RegExp(
  "^[ \t]*(([0-9]?[0-9])/([0-9]?[0-9])/(([0-9][0-9])?[0-9][0-9]))[ \t]*$"
);

export const DDSMMSYYYY_FORMAT: DateFormat = {
  parse(text: string): Date | null {
    const m = text.match(DDSMMSYYYY_DATE_REGEXP);
    if (m) {
      let year = parseInt(m[4], 10);
      const month = parseInt(m[3], 10);
      const day = parseInt(m[2], 10);

      //2-digit year handling is like Microsoft Excel
      //See: https://support.microsoft.com/en-au/help/214391/how-excel-works-with-two-digit-year-numbers
      if (year < 30) {
        year += 2000;
      } else if (year < 100) {
        year += 1900;
      }
      if (
        month >= 1 &&
        month <= 12 &&
        day >= 1 &&
        day <= daysInMonth(year, month)
      ) {
        return { year, month, day };
      }
    }
    return null;
  },
  format(date: Date): string {
    return "" + padn(date.day, 2) + "/" + padn(date.month, 2) + "/" + date.year;
  },
  template: "DD/MM/YYYY",
};

const DDMMYY_DATE_REGEXP = new RegExp(
  "^[ \t]*(([0-9][0-9])([0-9][0-9])([0-9][0-9]))[ \t]*$"
);

export const DDMMYY_FORMAT: DateFormat = {
  parse(text: string): Date | null {
    const m = text.match(DDMMYY_DATE_REGEXP);
    if (m) {
      const year = parseInt(m[4], 10) + 2000;
      const month = parseInt(m[3], 10);
      const day = parseInt(m[2], 10);
      if (
        month >= 1 &&
        month <= 12 &&
        day >= 1 &&
        day <= daysInMonth(year, month)
      ) {
        return { year, month, day };
      }
    }
    return null;
  },
  format(date: Date): string {
    return "" + padn(date.day, 2) + padn(date.month, 2) + (date.year % 100);
  },
  template: "DDMMYY",
};

export function mergeFormats(
  format: DateFormat,
  extraInputFormats: DateFormat[]
): DateFormat {
  return {
    parse(text: string): Date | null {
      for (const f of [format, ...extraInputFormats]) {
        const ndate = f.parse(text);
        if (ndate !== null) {
          return ndate;
        }
      }
      return null;
    },
    format: format.format,
    template: [format, ...extraInputFormats]
      .map((f) => f.template)
      .join(" or "),
  };
}

function padn(v: number, width: number): string {
  let s = "" + v;
  while (s.length < width) {
    s = "0" + s;
  }
  return s;
}

function daysInMonth(year: number, month: number): number {
  if (year % 4 === 0 && month === 2) {
    return 29;
  }
  return [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}
