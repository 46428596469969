import * as React from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Form,
  Label,
  TextArea,
} from "semantic-ui-react";

import { isJsonParseException, JsonBinding } from "./adl-gen/runtime/json";
import { VEditor } from "./adl-veditor";

// A form for entry of an arbirary ADL value . It is provided with an initial value and its
// ADL type. The onApply() callback is made when the user presses the apply button on validated
// input.

export interface AdlEditorProps<T> {
  value: unknown;
  veditor: VEditor<T, unknown, unknown>;
  onCancel?(): void;
  onClose?(): void;
  onApply?(value: T): void;
  disabled?: boolean;
  allowRaw?: JsonBinding<T>;
  validate?(value: T): Promise<string | null>;
}

enum Mode {
  VE, // Edit with the generated ADL VEditor
  RAW, // Edit with a (validated) json multiline text field
}

interface AwaitingValidation {
  type: "awaiting";
  validationSeq: number;
}

interface FormError {
  type: "error";
  error: string;
  validationSeq: number;
}

interface FormValidated {
  type: "ok";
  validationSeq: number;
}

type FormValidation = AwaitingValidation | FormError | FormValidated;

interface AdlEditorState {
  value0: unknown;
  adlState: unknown;
  rawState: string;
  mode: Mode;
  pristine: boolean;
  formValidation: FormValidation;
}

export class AdlEditor extends React.Component<
  AdlEditorProps<unknown>,
  AdlEditorState
> {
  // Internal state, which is re-initialized every time props change
  // @ts-ignore
  state: AdlEditorState;

  constructor(props: AdlEditorProps<unknown>) {
    super(props);
    this.initState(props);
  }

  initState(props: AdlEditorProps<unknown>) {
    this.state = {
      value0: props.value,
      adlState: this.mkState(props.value),
      pristine: true,
      rawState: this.mkRawState(props.value),
      mode: Mode.VE,
      formValidation: {
        type: "ok",
        validationSeq: 0,
      },
    };
  }

  mkState(value: unknown | null): unknown {
    return value === null
      ? this.props.veditor.initialState
      : this.props.veditor.stateFromValue(value);
  }

  mkRawState(value: unknown): string {
    return value !== null && this.props.allowRaw
      ? JSON.stringify(this.props.allowRaw.toJson(value), null, 2)
      : "";
  }

  render() {
    let errors: string[] = [];
    let renderedEditor: JSX.Element | null = null;

    switch (this.state?.mode) {
      case Mode.VE:
        errors = this.props.veditor.validate(this.state.adlState);
        renderedEditor = this.props.veditor.render(
          this.state.adlState,
          !this.props.disabled,
          this.onUpdate.bind(this)
        );
        break;
      case Mode.RAW:
        const result = this.parseRawText(this.state.rawState);
        const error =
          result.kind === "error" ? (
            <Label basic color="red">
              {result.error}
            </Label>
          ) : null;
        renderedEditor = (
          <div>
            <Form>
              <TextArea
                rows={20}
                style={{ fontFamily: "monospace" }}
                disabled={this.props.disabled}
                value={this.state.rawState}
                onChange={this.onRawChange}
              />
            </Form>
            {error}
          </div>
        );
        if (result.kind === "error") {
          errors = [result.error];
        }
        break;
      default:
    }

    const buttons: JSX.Element[] = [];
    if (this.props.onApply) {
      buttons.push(
        <Button
          primary
          loading={this.state?.formValidation.type === "awaiting"}
          disabled={errors.length > 0}
          key="Apply"
          onClick={this.onApply.bind(this)}
        >
          Apply
        </Button>
      );
    }
    if (this.props.onClose) {
      buttons.push(
        <Button key="Close" onClick={this.props.onClose}>
          Close
        </Button>
      );
    }
    if (this.props.onCancel) {
      buttons.push(
        <Button key="Cancel" onClick={this.props.onCancel}>
          Cancel
        </Button>
      );
    }

    let rawToggle: JSX.Element | null = null;
    if (this.props.allowRaw) {
      rawToggle = (
        <Checkbox
          toggle
          label="Edit raw"
          style={{ float: "right", margin: "8px" }}
          onClick={this.onToggleMode}
          checked={this.state?.mode === Mode.RAW}
        />
      );
    }

    let formError: JSX.Element | null = null;
    if (this.state?.formValidation.type === "error") {
      formError = (
        <div style={{ margin: "20px" }}>
          <Label basic color="red">
            {this.state.formValidation.error}
          </Label>
        </div>
      );
    }

    return (
      <div>
        <div>
          <div style={{ margin: "20px" }}>{renderedEditor}</div>
          {formError}
          <div style={{ margin: "20px" }}>
            {buttons}
            {rawToggle}
          </div>
        </div>
      </div>
    );
  }

  onToggleMode = (
    _event: React.SyntheticEvent<HTMLInputElement, MouseEvent>,
    _data: CheckboxProps
  ) => {
    switch (this.state?.mode) {
      case Mode.VE:
        // Update the rawstate if we can, before switching to raw mode
        const errors = this.props.veditor.validate(this.state.adlState);
        if (errors.length === 0 && this.props.allowRaw) {
          const value = this.props.veditor.valueFromState(this.state.adlState);
          const rawState = JSON.stringify(
            this.props.allowRaw.toJson(value),
            null,
            2
          );
          this.setState({ mode: Mode.RAW, rawState });
        } else {
          this.setState({ mode: Mode.RAW });
        }
        break;

      case Mode.RAW:
        // Update the veditor if we can, before switching to VE mode
        const result = this.parseRawText(this.state.rawState);
        if (result.kind === "value") {
          const adlState = this.props.veditor.stateFromValue(result.value);
          this.setState({ mode: Mode.VE, adlState });
        } else {
          this.setState({ mode: Mode.VE });
        }
        break;
      default:
    }
  };

  // tslint:disable-next-line: no-any
  onRawChange = (_event: any, data: any) => {
    this.setState({ rawState: data.value });
  };

  parseRawText = (
    text: string
  ): { kind: "error"; error: string } | { kind: "value"; value: unknown } => {
    try {
      const jv = JSON.parse(text);
      if (this.props.allowRaw) {
        const value = this.props.allowRaw.fromJson(jv);
        return { kind: "value", value };
      }
      return { kind: "error", error: "BUG: no allowRaw property" };
    } catch (e) {
      if (e instanceof SyntaxError) {
        return { kind: "error", error: "Json is not well formed" };
      } else if (isJsonParseException(e)) {
        return { kind: "error", error: e.getMessage() };
      } else {
        return { kind: "error", error: "Unknown error" };
      }
    }
  };

  // tslint:disable-next-line: no-any
  onUpdate(event: any) {
    const newAdlState = this.props.veditor.update(this.state?.adlState, event);
    this.setState({
      adlState: newAdlState,
      pristine: false,
    });
    void this.validateForm(newAdlState);
  }

  // tslint:disable-next-line: no-any
  async validateForm(adlState: any) {
    if (this.props.validate && this.state?.mode === Mode.VE) {
      const value = this.props.veditor.valueFromState(adlState);
      const validationSeq = this.state.formValidation.validationSeq + 1;
      this.setState({
        formValidation: {
          type: "awaiting",
          validationSeq,
        },
      });
      const error: string | null = await this.props.validate(value);
      if (validationSeq === this.state.formValidation.validationSeq) {
        if (error && error.length > 0) {
          this.setState({
            formValidation: {
              type: "error",
              error,
              validationSeq,
            },
          });
        } else {
          this.setState({
            formValidation: {
              type: "ok",
              validationSeq,
            },
          });
        }
      }
    }
  }

  onRevert() {
    this.setState({
      adlState: this.mkState(this.state?.value0),
      pristine: true,
    });
  }

  onApply() {
    let value: unknown = null;
    switch (this.state?.mode) {
      case Mode.VE:
        value = this.props.veditor.valueFromState(this.state.adlState);
        break;
      case Mode.RAW:
        if (this.props.allowRaw) {
          value = this.props.allowRaw.fromJson(JSON.parse(this.state.rawState));
        }
        break;
      default:
    }
    if (this.props.onApply) {
      this.props.onApply(value);
      this.setState({
        value0: value,
        pristine: true,
      });
    }
  }
}
