import { CssBaseline, Fade, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { observer } from "mobx-react";
import { SnackbarProvider } from "notistack";
import * as React from "react";
import { Suspense } from "react";
import "react-datepicker/dist/react-datepicker.min.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "src/contexts/AuthContext";
import { DialogProvider } from "src/contexts/DialogContext";
import { DrawerProvider } from "src/contexts/DrawerContext";
import { PopoverProvider } from "./contexts/PopoverContext";
import { RootMobxStoreProvider } from "./contexts/RootMobxStoreContext";
import { TssRoutes as Routes } from "./routes";
import { ErrorBoundary } from "./ui/components/system/error-handler/ErrorBoundary";
import { ModalCanvas } from "./ui/components/modal/ModalCanvas";
import { ProgressFallback } from "./ui/components/system/ProgressFallback";
import { theme } from "./configuration/theme";
import { DialogWithContext } from "./ui/temporaryComponentsToShare/DialogWithContext/DialogWithContext";
import { Auth0ProviderWithConfig } from "src/contexts/Auth0Context";

export const App = observer(() => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 10_000,
      },
    },
  });

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      TransitionComponent={Fade}
    >
      <DialogProvider>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore this code flaps between compiling and not depending on env */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CssBaseline />
                <Auth0ProviderWithConfig>
                  <RootMobxStoreProvider>
                    <Suspense fallback={<ProgressFallback />}>
                      <BrowserRouter>
                        <AuthProvider>
                          <ModalCanvas />
                          <PopoverProvider>
                            <DrawerProvider>
                              <Routes />
                              <DialogWithContext />
                            </DrawerProvider>
                          </PopoverProvider>
                        </AuthProvider>
                      </BrowserRouter>
                    </Suspense>
                  </RootMobxStoreProvider>
                </Auth0ProviderWithConfig>
              </LocalizationProvider>
            </ThemeProvider>
          </ErrorBoundary>
        </QueryClientProvider>
      </DialogProvider>
    </SnackbarProvider>
  );
});
